import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";
import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";

import { LargeText, SmallTitle, MediumTitle } from "../../ui-components/texts";

import { useTranslate } from "../../contexts/translate";

// images
import imageCover from "../../media/images/portfolio-images/taormina/image-cover.png";
import { ImageLeftWithText } from "./components-content";

import {
	Column,
	Container,
	ContainerBlock,
} from "../../ui-components/columns/components";
import { TitleWithScrollXImagesSection } from "./components-design";



import { FullWidthVideo } from "../../ui-components/videos";

const Taormina = ({
	setNextPageHref = () => {},
	setNextPageName = () => {},
}) => {
	useEffect(() => {
		setNextPageHref("/projects/topbuildinggroup");
		setNextPageName("Top Building Group");
		document.title =
			"Taormina Caffè - Cutting Edge Digital Creative Studio";
	}, []);

	const { translations } = useTranslate();
	const peoples = [
		{
			fullname: "Andrea Anfuso",
			image: require("../../media/team-images/Andrea Anfuso.jpg").default,
			role: translations === null ? "" : translations.about.peoples[0].role,
			description:
				translations === null ? "" : translations.about.peoples[0].description,
		},
		{
			fullname: "Giuseppe Bussolari",
			image: require("../../media/team-images/Giuseppe Bussolari.jpg").default,
			role: translations === null ? "" : translations.about.peoples[8].role,
			description:
				translations === null ? "" : translations.about.peoples[8].description,
		},
		{
			fullname: "Luca Colombo",
			image: require("../../media/team-images/no-image.png").default,
			role: translations === null ? "" : translations.about.peoples[19].role,
			description:
				translations === null ? "" : translations.about.peoples[19].description,
		},
		
	];

	return (
		<div className="project-page project-page-cips">
			<CoverPorfolio
				background="#153B59"
				className="theme-dark"
				title="Taormina Caffè"
				white={true}
				text={translations && translations.taormina.intro}
				image={imageCover}
			/>

			<DetailsProject
				client={"Taormina Caffè"}
				services={[
					"Brand Strategy",
					"Content Production",
					"Social Media Management",
					"Advertising ",
				]}
				timeframe={"2023"}
				introduction={
					<>
						<LargeText>
							{translations && translations.taormina.descriptions[0]}
						</LargeText>
					</>
				}
			/>

			<TitleWithScrollXImagesSection
				className="theme-dark"
				color={"#3C4043"}
				title={translations && translations.taormina.sections[0].title}
				text={translations && translations.taormina.sections[0].text1}
				items={[
					{
						src: require("../../media/images/portfolio-images/taormina/gallery/1.png")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/taormina/gallery/2.png")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/taormina/gallery/3.png")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/taormina/gallery/4.png")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/taormina/gallery/5.png")
							.default,
					}
				]}
			/>
<section className="theme-light" style={{ background: "#fff" }}>
				<Container>
					<div className="wrapper fluid pt-5 pb-0">
						<Column>
							<MediumTitle>
								{translations && translations.taormina.sections[1].title}
							</MediumTitle>
							<LargeText>
								{translations && translations.taormina.sections[1].text}
							</LargeText>
							
						</Column>
					</div>
				</Container>

				
			</section>


			<ImageLeftWithText
				className="theme-light"
				color="#fff"
				//title={translations && translations.ohoskin.sections[0].title}
				texts={[translations && translations.taormina.sections[2].title, translations && translations.taormina.sections[2].text]}
				image={
					require("../../media/images/portfolio-images/taormina/image-sx.png")
						.default
				}
			/>
			<TitleWithScrollXImagesSection
			   white={false}
				color={"#f5d99a"}
				className="theme-light"
				title={translations && translations.taormina.sections[3].title}
				text={translations && translations.taormina.sections[3].text}
				
			/>

			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
				<div className="cta-single-project-inner">
					<p>{translations && translations.ctasingleproject.text}</p>
					<PopupButton id="egC3mNyH">
						{translations && translations.ctasingleproject.button}
					</PopupButton>
				</div>
			</div>
		</div>
	);
};

Taormina.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default Taormina;
